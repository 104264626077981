import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import Macquarie1 from "../../../assets/projects/macquarie_1.jpg"
import Macquarie2 from "../../../assets/projects/macquarie_2.jpg"
import Macquarie3 from "../../../assets/projects/macquarie_3.jpg"
import Macquarie4 from "../../../assets/projects/macquarie_4.jpg"
import Macquarie5 from "../../../assets/projects/macquarie_5.jpg"
import Macquarie6 from "../../../assets/projects/macquarie_6.jpg"
import Macquarie7 from "../../../assets/projects/macquarie_7.jpg"
import Macquarie8 from "../../../assets/projects/macquarie_8.jpg"
import Macquarie9 from "../../../assets/projects/macquarie_9.jpg"
import Macquarie10 from "../../../assets/projects/macquarie_10.jpg"
import Macquarie11 from "../../../assets/projects/macquarie_11.jpg"
import Macquarie12 from "../../../assets/projects/macquarie_12.jpg"
import Macquarie13 from "../../../assets/projects/macquarie_13.jpg"
import Macquarie14 from "../../../assets/projects/macquarie_14.jpg"
import Macquarie15 from "../../../assets/projects/macquarie_15.jpg"
import Macquarie16 from "../../../assets/projects/macquarie_16.jpg"
import Macquarie17 from "../../../assets/projects/macquarie_17.jpg"
import Macquarie18 from "../../../assets/projects/macquarie_18.jpg"
import Macquarie19 from "../../../assets/projects/macquarie_19.jpg"
import Macquarie20 from "../../../assets/projects/macquarie_20.jpg"
import Macquarie21 from "../../../assets/projects/macquarie_21.jpg"
import Macquarie22 from "../../../assets/projects/macquarie_22.jpg"
import Depy from "../../../assets/projects/depy.jpg"
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const MacquarieProject = () => {
  DocumentTitle("Jeff Lau - Macquarie Group");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Macquarie Bank</strong></h3>
              <h3>Design System - Product Design & Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Macquarie’s digital design system supports seven products across multiple operating groups. It is a comprehensive design system that covers over 90% of the UI design output, enabling development teams to efficiently create new functionality, regardless of the team’s UI development skills.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie2} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Design System</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Within this project, I was responsible for creating an end-to-end design system and later product owned the design system for the five products.</p>
              <p>When first engaged with the design system, there were only two sprint teams and surprisingly there were already extensive inconsistencies and duplication evident throughout the UI development. Auditing existing design and UI development was the first step to demonstrate the need of a design system and gain buy in from the teams.</p>
              <p>As part of forward modelling and other agile ceremonies, I designed and developed the necessary components and patterns in preparation for upcoming sprints. This process significantly reduced technical debt and prevented development slippage.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie8} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>Through a process of continuous improvement, I comprehensively tested for accessibility requirements, tested with users, finetuned the visual correctness, enhanced behaviour and further optimised the modularity of the design system.</p>
              <p>The design system is responsively designed to handle mobile, tablet and desktop resolutions.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie9} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie10} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie6} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.content}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <h3>The Macquarie OneUI design system is now the single source of truth for the associated sprint teams with business analysts, designers, developers and testers all relying on relevant rules and output. It is future proofed to support new products and modify existing design in an efficient manner.</h3>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie3} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie4} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie5} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Over the last year I've acted as the principal accessibility consultant to the majority of products created throughout the Macquarie Group for business units including Macquarie.com, Macquarie.com.au, Macquarie Personal Banking, Macquarie Advisory, Macquarie Business Bank, Macquarie Property Management, Macquarie Broker Portal. My accessibility testing experience includes testing responsively design solutions, native mobile applications for both iPhone and Android, PDFs, desktop applications and other internal staff systems.</p>
              <p>Many products within Macquarie rely upon on vendor solutions and all too often these vendor solutions have a high number of accessibility issues that can't easily be resolved without adjusting the code base of the vendor products, this effort requires working closely with vendor design & development teams to apply changes that meet the designated accessibility requirements.</p>
              <p>In my time at Macquarie I've provided strategic advice on embedding and succeeding with accessibility, pre-engagement vendor assessments, key input to internal initiatives that fuse accessibility into product plans, providing external communication that represents Macquarie, education for all types of team and levels, constructing documentation for various disciplines, testing on all types of assistive technology and working with real users to understand and identify accessibility issues in Macquarie’s products.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie1} />
          </div>
        </MainWrapper>
      </section>
{/* 
      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>The objective was to re-design the global search that addresses the needs of three of our core products; personal banking, investment and business.</p>
              <p>The project began by understanding the problem. Running workshops with stakeholders we created hypothesis, metrics, to understand the problem and the value that the initative would provide us.</p>
            </div>
          </div>
        </MainWrapper>
      </section>


      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie11} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie12} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie13} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.content}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <p>This project involved consolidating data from Google Analytics, Medallia to gain an understanding the users pain points. Compling 350+ data points across 3 products to understanding the needs of 15+ personas.</p>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie14} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie15} />
          </div>
        </MainWrapper>
      </section>


      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>Workshops were created to understand how users search. From seeking information, querying the search box, sending that to the engine, getting and evaluating results and repeating this process until they get the results they need.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie16} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie17} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Macquarie product" src={Macquarie18} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>Ultimately, creating 85+ concepts that were throughly tested with 300+ participants. With each concept exploring ideas such as quick actions, calculators, spell check, natural language and more.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie19} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie20} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>Creating transparency with senior stakeholders with concepts that worked well and ones that require re-addressing or pivoting.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie21} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>To finally applying the creating high-fidelity UI designs.</p>
            </div>
          </div>
        </MainWrapper>
      </section> */}

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Macquarie product" src={Macquarie7} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.quote}>
        <MainWrapper size="8">
          <h3>“Your amazing positive energy that you bring to every meeting and every interaction. It's a delight to work with you - your 'can do' attitude. There was nothing that I have personally asked you to do and you haven't been able to do it. You always go above and beyond.”</h3>
          <div className={styles.profile}>
            <div className={styles.profilePicture}>
              <img alt="Depy" src={Depy} />
            </div>
            <div className={styles.profileDetails}>
              <p><strong>Depy Kyristi</strong></p>
              <p>Head of User Experience</p>
            </div>
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default MacquarieProject;
