import styles from "./Clients.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";

const Clients = (props) => {
  DocumentTitle("Jeff Lau - Clients");

  return (
    <>
      <section className={styles.mainTitle}>
        <MainWrapper size="10">
          <h1>I've worked with everyone from early stage startups to ASX100 companies - building products people love.</h1>
        </MainWrapper>
      </section>

      <section className={styles.clients}>
        <MainWrapper size="10">
          <div className={styles.clientContainer}>
            <div className={styles.client}>
              <p><strong>Abbott Laboratories</strong></p>
              <p>Design & development of eDetailer for doctors.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Alchemy Architecture</strong></p>
              <p>Brand identity, web design, and style guide for Sydney-based architecture company.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Amgen</strong></p>
              <p>Design and development for cholesterol drug Repatha.</p>
            </div>
            <div className={styles.client}>
              <p><strong>AMP Services</strong></p>
              <p>Design concepts and exploration for a AMP My Portfolio.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Arthritis Australia</strong></p>
              <p>Brand identity, UI/UX design, and website for a arthritis charity.</p>
            </div>
            <div className={styles.client}>
              <p><strong>AstraZeneca</strong></p>
              <p>Design & development of eDetailer for Naropin</p>
            </div>
            <div className={styles.client}>
              <p><strong>Australian Community Business Network (ABCN)</strong></p>
              <p>Ongoing web design and development with ABCN's team.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Australian Digital Health Agency (ADHA)</strong></p>
              <p>Strategy, web design, and development for the future of digital healthcare in Australia</p>
            </div>
            <div className={styles.client}>
              <p><strong>Australian Tutoring Association (ATA)</strong></p>
              <p>Branding, web design, and development for a tutoring association.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Bayer</strong></p>
              <p>Augmented reality experience and eDetailers for healthcare professionals.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Campbelltown Art Centre</strong></p>
              <p>Brand identity, design, and development for an art gallery.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Consensys</strong></p>
              <p>Strategy and UI/UX design for start-up, health-blockchain Sahya.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Fairfax Media Group</strong></p>
              <p>Design and identity for the Australian Financial Review.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Fairfield RSL</strong></p>
              <p>Brand identity, web design, and style guide for a RSL club.</p>
            </div>
            <div className={styles.client}>
              <p><strong>GlaxoSmithKline (GSK)</strong></p>
              <p>Creative vision for GSK's travel vaccine video.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Gordon Brothers</strong></p>
              <p>UI/UX design and style guide for an global advisory, restructuring and investment firm.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Janssen</strong></p>
              <p>Branding and UI/UX design for myStelara and Simply for Me app.</p>
            </div>
            <div className={styles.client}>
              <p><strong>JP Morgan</strong></p>
              <p>Design for the August 15 Spotlight Event</p>
            </div>
            <div className={styles.client}>
              <p><strong>Lilly & Co</strong></p>
              <p>User experience and interaction design for Forteo HCP Portal</p>
            </div>
            <div className={styles.client}>
              <p><strong>Loan Base</strong></p>
              <p>UI/UX design, prototyping, and user testing for home loans.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Macquarie Group</strong></p>
              <p>Design system product designer working with 7 products</p>
            </div>
            <div className={styles.client}>
              <p><strong>Menarini</strong></p>
              <p>Website design & development of Seremind & Labixten</p>
            </div>
            <div className={styles.client}>
              <p><strong>Mundipharma</strong></p>
              <p>Brand identity, style guide, and app design for asthma app.</p>
            </div>
            <div className={styles.client}>
              <p><strong>News Corp</strong></p>
              <p>Strategy and design concepts for internal markup tool</p>
            </div>
            <div className={styles.client}>
              <p><strong>Novartis</strong></p>
              <p>Creative lead and 3D animation of CAR-T</p>
            </div>
            <div className={styles.client}>
              <p><strong>NSW Government (Health)</strong></p>
              <p>Working with NSW Health to create event site</p>
            </div>
            <div className={styles.client}>
              <p><strong>Pfizer</strong></p>
              <p>User experience and interaction design for Haemophilia</p>
            </div>
            <div className={styles.client}>
              <p><strong>Raini EFT</strong></p>
              <p>Ongoing frontend and backend development collaboration.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Ruby Red Foundation</strong></p>
              <p>Web design and guidelines for charity organisation.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Ryde Baptist Church</strong></p>
              <p>Web design, content, and development for church.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Sanofi</strong></p>
              <p>Visual design, illustrations, and icons for Sanofi Vivaxim</p>
            </div>
            <div className={styles.client}>
              <p><strong>SDI Pola</strong></p>
              <p>Brand identity, UI/UX design, and website for a Pola White/Day.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Seqirus</strong></p>
              <p>Strategy, web design, and development for Shingles website</p>
            </div>
            <div className={styles.client}>
              <p><strong>SHOUT</strong></p>
              <p>UI/UX design and development for self help groups.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Successful Ways</strong></p>
              <p>UI/UX design and development projects for brokers.</p>
            </div>
            <div className={styles.client}>
              <p><strong>The C:WK</strong></p>
              <p>Brand identity, web design, and style guide for event agency.</p>
            </div>
            <div className={styles.client}>
              <p><strong>The Limbic</strong></p>
              <p>Ongoing web design and development with Australia's largest news for healthcare professionals.</p>
            </div>
            <div className={styles.client}>
              <p><strong>University of New South Wales</strong></p>
              <p>Digital transformation and UX design for UNSW.</p>
            </div>
            <div className={styles.client}>
              <p><strong>University of Sydney</strong></p>
              <p>Creative for University of Sydney's publications.</p>
            </div>
            <div className={styles.client}>
              <p><strong>Virbac</strong></p>
              <p>UX research and design for Horse Health Hub.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

    </>
  );
};

export default Clients;
