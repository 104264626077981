import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";
import Limbic1 from "../../../assets/projects/thelimbic_1.jpg";
import Limbic2 from "../../../assets/projects/thelimbic_2.jpg";
import Limbic3 from "../../../assets/projects/thelimbic_3.jpg";
import Limbic4 from "../../../assets/projects/thelimbic_4.jpg";

const TheLimbicProject = () => {
  DocumentTitle("Jeff Lau - The Limbic");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>The Limbic</strong></h3>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>The limbic is an online publication sharing the latest healthcare news with medical specialists.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Limbic product"  src={Limbic1} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="ADHA product" src={Limbic3} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="ADHA product" src={Limbic4} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Healthcare specilaists are time-poor. Our aim was to create an experience which allowed users, to browse the articles and content with minimal effort. The resulting site combines a simple interface with beautiful typography and white space to create a sense of calm and ease of use. Maintance is handled with WordPress and user accounts are managed through a highly customised MailChimp integration.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Limbic product"  src={Limbic2} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default TheLimbicProject;
