const Logo = () => {
    return (
        <svg width="33" height="59" viewBox="0 0 33 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4287 12.5714C20.4287 5.62843 26.0571 0 33.0001 0V45.5714C33.0001 52.5144 27.3717 58.1429 20.4287 58.1429V12.5714Z" fill="black" />
            <path d="M0 26.7143C6.94301 26.7143 12.5714 32.3427 12.5714 39.2857V58.1429C5.62842 58.1429 0 52.5144 0 45.5714V26.7143Z" fill="black" />
        </svg>
    );
};

export default Logo;
