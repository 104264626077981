import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import CWK1 from "../../../assets/projects/cwk_1.jpg";
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const TheCWKProject = () => {
  DocumentTitle("Jeff Lau - The Company We Keep");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>The C:WK</strong></h3>
              <h3>UI Website Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>The Company We Keep is an event consulting agency specialising in events and marketing.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="CWK product" src={CWK1} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default TheCWKProject;
