import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import Fairfield1 from "../../../assets/projects/fairfield_1.jpg";
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const FairfieldProject = () => {
  DocumentTitle("Jeff Lau - Fairfield RSL");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Fairfield RSL</strong></h3>
              <h3>UI Website Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>
                Fairfield is a melting pot, a crucible of different cultures.  I was the lead designer to create a new brand identity, web design, and style guide for a RSL club.The site is rich with music, video, photography and animation creating an experience which touches the senses.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Fairfield product" src={Fairfield1} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default FairfieldProject;
