import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import LoanBase1 from "../../../assets/projects/loanbase_1.jpg";
import LoanBase2 from "../../../assets/projects/loanbase_2.jpg";
import LoanBase3 from "../../../assets/projects/loanbase_3.jpg";
import LoanBase4 from "../../../assets/projects/loanbase_4.jpg";
import LoanBase5 from "../../../assets/projects/loanbase_5.jpg";
import LoanBase6 from "../../../assets/projects/loanbase_6.jpg";
import LoanBase7 from "../../../assets/projects/loanbase_7.jpg";
import LoanBase8 from "../../../assets/projects/loanbase_8.jpg";
import LoanBase9 from "../../../assets/projects/loanbase_9.jpg";
import JJ from "../../../assets/projects/jj.jpg";
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const LoanBaseProject = () => {
  DocumentTitle("Jeff Lau - Loan Base");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Loan Base</strong></h3>
              <h3>Product Design, Design System & Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Loan Base is an Australian brokering business. They guide the user through the entire loan process. They have experience with residential and commercial lending and are passionate about providing customers with knowledge and expertise.</p>
              <p>As a lead designer, we created the Loan Base experience starting with workshops and interviews with existing and new customers who are trying to find a home loan. Our research was synthesised into various design artefacts where we continuously validated with customers. With these design artefacts we engaged with subject matter experts within feature teams to understand the various stages of the loan journey.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Loan Base product"  src={LoanBase2} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Loan Base product"  src={LoanBase3} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Loan Base product"  src={LoanBase4} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Through discovery sessions with key stakeholders we applied a design thinking process to identify needs. Ideation followed where we refined our concepts over several rounds and moved into prototyping. The prototype the team created demonstrates a seamless experience where users can remain on the portal to explore, browse and submit a loan application.</p>
              <p>Loan Base is responsively designed allowing a consistent presentation of data across all mediums and devices, my approach to responsive design is to include the same content and functionality across all devices. This is a complete responsively designed experience serving the same content and functionality to all users.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="Loan Base product"  src={LoanBase7} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>The design that we've created for Loan Base has been delivery focused, we've worked with product owners, business stakeholders and client experience managers to produce an array of design artefeacts used to have teams developing and releasing features as quickly as possible. Some design activities include concept and prototype testing, technical workflows, design feasability assessments, design thinking, information architecture, visual design, user journeys and design showcases.</p>
              <p>As part of agile delivery I have applied inclusive design and accessibility initiatives into the teams responsible for building Loan Base, the definition of ready and definition of done includes specific accessibility requirements that must be met before starting or closing a feature.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Loan Base product"  src={LoanBase6} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Loan Base product"  src={LoanBase5} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Design System</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Once the user experience design and visual design was consistent, I persuaded the Program Directors and Sponsors to form a design system team tasked with creating a single source of truth pattern library within the development environment. At this point I formed and managed the design system team which consisted of 12 local resources and over 30 offshore resources. The core team included 3 UI developers, 5 JavaScript developers, 2 UX designers and 2 .NET Developers.</p>
              <p>The team created 92 patterns/components for the desktop solution and 54 patterns/components for the mobile specific experience. In agreement with my team, we shifted from the waterfall methodology to a more agile approach and were able to deliver the patterns/components in quick succession. The .NET developers of each feature team were educated on usage and were subsequently able to implement the modular assets into their features more rapidly.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Loan Base product"  src={LoanBase1} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>I was able to implement a complete end to end accessibility strategy which enabled me to embed many initiatives early in the design, development and testing phases to ensure potential accessibility issues were mitigated. In turn a low number of accessibility issues were identified and the defect numbers continued reduce from one release to the next.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Loan Base product"  src={LoanBase8} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Loan Base product"  src={LoanBase9} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.content}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <p>As the lead designer, Loan Base was a success - the company settled over $100M+ loans in the first month, grew the company by 225% in the first year and month after month we saw steady incline in NPS (averaged +5.1/month in the first year - now at 76).</p>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.quote}>
        <MainWrapper size="8">
          <h3>"Jeff and his team are a real pleasure to work with. They are well rounded with a solid understanding of design/user experience best practices as well as diving deep under the hood with superior development capabilities.”</h3>
          <div className={styles.profile}>
            <div className={styles.profilePicture}>
              <img alt="JJ"  src={JJ} />
            </div>
            <div className={styles.profileDetails}>
              <p><strong>JJ Fiasson</strong></p>
              <p>Director (Product Owner), Loan Base</p>
            </div>
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default LoanBaseProject;
