import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import Arthritis1 from "../../../assets/projects/arthritis_1.jpg";
import Arthritis2 from "../../../assets/projects/arthritis_2.jpg";
import Arthritis3 from "../../../assets/projects/arthritis_3.jpg";
import Arthritis4 from "../../../assets/projects/arthritis_4.jpg";
import Arthritis5 from "../../../assets/projects/arthritis_5.jpg";
import Arthritis6 from "../../../assets/projects/arthritis_6.jpg";
import Arthritis7 from "../../../assets/projects/arthritis_7.jpg";
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const ArthritisAustraliaProject = () => {
  DocumentTitle("Jeff Lau - Arthritis Australia");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Arthritis Australia</strong></h3>
              <h3>Product Design, Design System & Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Arthritis Australia is a charitable, not-for-profit organisation and the peak arthritis consumer body in Australia. They provides support and information to people with arthritis as well as their families and friends and promotes awareness of the challenges facing people with arthritis across the community, and to leaders in business, industry, and government.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Arthritis Australia product"  src={Arthritis3} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Arthritis Australia product"  src={Arthritis4} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>I was the lead product designer across three products - MyJointPain, Empowered and MyBackPain</p>
              <p>I was responsible for the re-design of Arthritis Australia that aims to compile and sort through 500+ existing articles</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Arthritis Australia product"  src={Arthritis2} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Design System</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>My engagement commenced with educating the development team about the benefits of a design system, leading a series of workshops to plan the implementation effort. I provided guidance regarding how to use the design system in a sustainable way, and advised how to correctly extend components and patterns for the unique needs of their product.</p>
              <p>The success of the integration project can be attributed to my recommended approach of implementing all HTML, CSS and JavaScript as intended per component and pattern. Often in such projects, only the presentational layer is applied onto an existing or legacy user interface, resulting in significant rework and defects.</p>
              <p>Under my guidance, the design system was implemented with minimal accessibility and user interface related defects, ensuring a faster delivery of the Arthritis Australia’s product to customers.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Arthritis Australia product"  src={Arthritis7} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>With an enthusiastic team including management and stakeholders we were able to drive accessibility throughout releases in a very end to end sense. Accessibility requirements were embedded into the agile process where it became a part of the definition of ready within the forward modelling sprints. Development sprints contained various accessibility requirements specific to the functionality being built that the development teams had to meet, these accessibility requirements became a part of the definition of done. Throughout the design and development phases there was frequent accessibility testing which lead to the development and testing teams utilising assistive technologies as part of their testing tool set.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Arthritis Australia product"  src={Arthritis6} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
            <p>A team focused on meeting accessibility truly made a substantial difference in the number of accessibility related defects identified in testing phases, furthermore when issues were raised the associated teams were able to react quickly and remediate the issues within the active sprint. Being responsible for driving accessibility throughout the app is my primary achievement, all team members have a greater understanding regarding the importance of accessibility.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Arthritis Australia product"  src={Arthritis5} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Arthritis Australia product"  src={Arthritis1} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default ArthritisAustraliaProject;
