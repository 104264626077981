import React from "react"
import { useState } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import styles from "./ImageCarousel.module.scss";
import Macquarie1 from "../../assets/projects/macquarie_1.jpg";
import LoanBase1 from "../../assets/projects/loanbase_1.jpg";
import Arthritis1 from "../../assets/projects/arthritis_1.jpg";
import NewsCorp1 from "../../assets/projects/newscorp_1.jpg";
import Adha1 from "../../assets/projects/adha_1.jpg";
import Mundi1 from "../../assets/projects/mundi_1.jpg";
import Fairfield1 from "../../assets/projects/fairfield_1.jpg";
import CWK1 from "../../assets/projects/cwk_1.jpg";
import Limbic1 from "../../assets/projects/thelimbic_1.jpg";
import CAC1 from "../../assets/projects/cac_1.jpg";
import ABCN1 from "../../assets/projects/abcn_1.jpg";
import Image from "./Image";

const projectsData = [
  {
    image: Macquarie1,
  },
  {
    image: LoanBase1,
  },
  {
    image: Arthritis1,
  },
  {
    image: NewsCorp1,
  },
  {
    image: Adha1,
  },
  {
    image: Mundi1,
  },
  {
    image: Fairfield1,
  },
  {
    image: CWK1,
  },
  {
    image: Limbic1,
  },
  {
    image: CAC1,
  },
  {
    image: ABCN1,
  }
];

const ImageCarousel = () => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const onSlider = React.useRef(null);
  const [currentSlide, setCurrentSlider] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 5,
    duration: 1000,
    centered: true,
    loop: true,
    spacing: 0,
    slideChanged(slide) {
      setCurrentSlider(slide.details().relativeSlide);
    },
    breakpoints: {
      '(max-width: 780px)': {
        slidesPerView: 3,
      },
    },
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    onSlider.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    onSlider.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [onSlider])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 4000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <div ref={onSlider} className={styles.ImageCarousel}>
      <div className={styles.container}>
        <div ref={sliderRef} className={`${styles.slider} keen-slider`}>
          {projectsData.map((project, index) => (
            <Image 
              key={index}
              project={project} 
              index={index} 
              slider={slider} 
              currentSlide={currentSlide} 
              />
          ))}
        </div>
      </div>
    </div>
  );

};

export default ImageCarousel;
