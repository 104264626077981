import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import ADHA1 from "../../../assets/projects/adha_1.jpg"
import ADHA2 from "../../../assets/projects/adha_2.jpg"
import ADHA3 from "../../../assets/projects/adha_3.jpg"
import ADHA4 from "../../../assets/projects/adha_4.jpg"
import ADHA5 from "../../../assets/projects/adha_5.jpg"
import Ross from "../../../assets/projects/ross.jpg"
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const ADHAProject = () => {
  DocumentTitle("Jeff Lau - ADHA");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Australian Digital Health Agency</strong></h3>
              <h3>Product Design & Accessibility</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>The Australian Digital Health is an Australian Government Agency that is responsible for national digital health design and strategy.</p>
              <p>The objective of this project is to design & develop a website that will be used to collect data on the Australia’s of Digital Health Strategy for 2022.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="ADHA product" src={ADHA1} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>I enjoy understanding users and taking those associated learnings to utilise as rationale throughout the design process, my designs are based on these user derived learnings and best practices.</p>
              <p>We performed a total of 12 rounds of moderated usertesting across all age ranges to understand how users interacted with the website</p>
              <p>Working onsite and closely with the client we were able to iterate our designs quickly and then validate with users, this rapid prototyping provided the team with additional time to focus on the final deliverable.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="ADHA product" src={ADHA2} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>For our final deliverable we produced a highly interactive prototype with finished visual design applied, consolidating vast amounts of information addressing all the users needs</p>
              <p>Hands on building and overseeing the efforts made by front end development team members to ensure consistency and front end development best practices were applied</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="ADHA product" src={ADHA4} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="ADHA product" src={ADHA5} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Design System</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>I consolidated the effort of a team of five designers to create the Australian Digital Health design system. The design system was intended to guide the incoming development team on explicit components and patterns relevant to the Loan Base experience.</p>
              <p>The comprehensive design system contained accessibility patterns and recommendations for each component, as well as semantic markup and interactivity recommendations, providing a blueprint for how each component or pattern is structured in HTML and how it should behave.</p>
              <p>I collaborated with each design team member, working to understand their specific requirements for the features they were designing. This was key to the success of the system as it had to flexible for all design being produced. Throughout this process, the team negotiated various components and patterns in use across each feature, with all variations ultimately distilled into a singular component, which was then implemented across all feature teams.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="ADHA product" src={ADHA1} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="ADHA product" src={ADHA3} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.quote}>
        <MainWrapper size="8">
          <h3>“Jeff is amazing & highly skilled - he was fantastic at making us understand the importance of human centric design.”</h3>
          <div className={styles.profile}>
            <div className={styles.profilePicture}>
              <img alt="Ross" src={Ross} />
            </div>
            <div className={styles.profileDetails}>
              <p><strong>Ross TheBridge</strong></p>
              <p>Product Owner</p>
            </div>
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default ADHAProject;
