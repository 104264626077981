import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import ABCN1 from "../../../assets/projects/abcn_1.jpg";
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const ABCNProject = () => {
  DocumentTitle("Jeff Lau - ABCN");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Fairfield RSL</strong></h3>
              <h3>UI Website Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>The Australian Business and Community Network is a group of over 200 low socio-economic status schools and over 40 leading businesses, working together to address educational disadvantage through structured workplace mentoring and business/school partnerships.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="ABCN Product" src={ABCN1} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default ABCNProject;
