import styles from "./Footer.module.scss";
import MainWrapper from "../MainWrapper/MainWrapper";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <MainWrapper>
        <div>
          <h1>Let's talk</h1>
          <h3><a href="mailto:jeff@owlry.com.au">jeff@owlry.com.au</a></h3>
        </div>
        <div className={styles.twoColumn}>
          <div className={styles.leftColumn}>
            <p>
              24 Station Street, Thornleigh
              <br />
              NSW, 2120 Australia
            </p>
            <p>
              <a href="tel:+61 488 332 122">+61 488 332 122</a>
              <br />
              Designed & developed by Jeff Lau
            </p>
          </div>
          <ul className={styles.rightColumn}>
            <li><Link to="/projects">Projects</Link></li>
            <li><Link to="/clients">Clients</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </MainWrapper>
    </footer>
  );
};

export default Footer;
