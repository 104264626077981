import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";
import CAC1 from "../../../assets/projects/cac_1.jpg";

const CampbelltownProject = () => {
  DocumentTitle("Jeff Lau - Campbelltown Art Centre");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Campbelltown Art Centre</strong></h3>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Campbelltown Arts Centre is a multidisciplinary contemporary arts centre located in Campbelltown, New South Wales, south west of Sydney, Australia. It is a cultural facility of Campbelltown City Council, assisted by other government funding and private sponsorships.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="CAC product" src={CAC1} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default CampbelltownProject;
