// import styles from "./CompetenciesAccordion.module.scss";
import Collapse from "rc-collapse/lib/Collapse";
import { Panel } from "rc-collapse";
import './CompetenciesAccordion.css';
import Chevron from "../../assets/Chevron";

const CompetenciesAccordion = () => {
  return (
    <Collapse accordion={true} defaultActiveKey={0}>
      <Panel header={<><Chevron />User experience (UX)</>} showArrow={false}>
        <p>With a long history in user experience design, I've been fortunate to work on a wide range of solutions and products, across a diverse set of mediums. I've crafted products independently from design discovery, following the double diamond design process model, through to delivery and beyond. I have also worked collaboratively within both small and large teams, working toward a shared goal.</p>
        <p>I enjoy understanding users and taking those associated learnings to utilise as rationale throughout the design process, my designs are based on these user derived learnings and best practices.</p>
        <p>My technical knowledge has served me well, ensuring important features such as accessibility, sustainable design and technical feasibility, are considered well before reaching the development teams. These skills have contributed to the delivery of many quality projects and solutions.</p>
      </Panel>
      <Panel header={<><Chevron />User interface (UI)</>} showArrow={false}>
        <p>Much of my history has been UI design, where I focus on anticipating what users might need to do and ensuring that the interface has elements that are easy to access, understand, and use to facilitate those actions.</p>
        <p>I enjoy solving complex UI problems and am motivated to produce the most streamlined, semantic, modular, efficient and accessible UI experiences, which can be quite challenging in corporate environments that rely on legacy systems, vendor software or older code bases.</p>
        <p>Recently, I have been educating front end and UI developers to create accessible, semantic and modular experiences. As such I have a long list of developers whose skillsets and careers directly benefit from having learnt best practice techniques from me.</p>
      </Panel>
      <Panel header={<><Chevron />Design system</>} showArrow={false}>
        <p>From both a design and development perspective, design systems play a key role in my process – they form the cornerstone of my approach to bridging the gap between design and development.</p>
        <p>For more than 6 years I have created, designed, developed, product owned and managed teams tasked with building design systems. In that time, I have been responsible for producing design guidelines, design system strategy, design and development specifications, style guides, applying accessibility to design systems, pattern libraries and component libraries. My design system experience includes unifying design across responsive web and device specific applications.</p>
      </Panel>
      <Panel header={<><Chevron />Accessibility</>} showArrow={false}>
        <p>My experience is end-to-end, consulting on accessibility strategy and organisational compliance, through to providing complex technical assessments and remediating existing solutions with teams of various sizes.</p>
        <p>For the last two years I have been a participant on the Web Content Accessibility Guidelines (WCAG) Working Group, where I contributed towards the release of the WCAG 2.1 Guidelines.</p>
      </Panel>
    </Collapse>
  );
};

export default CompetenciesAccordion;
