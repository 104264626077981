import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import Mundi1 from "../../../assets/projects/mundi_1.jpg"
import Mundi2 from "../../../assets/projects/mundi_2.jpg"
import Mundi3 from "../../../assets/projects/mundi_3.jpg"
import Mundi4 from "../../../assets/projects/mundi_4.jpg"
import Mundi5 from "../../../assets/projects/mundi_5.jpg"
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const MundipharmaProject = () => {
  DocumentTitle("Jeff Lau - Mundipharma");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>Mundipharma</strong></h3>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>Breatherite is a personalised patient support app improving the lives of people with asthma. The app that updates the user based on air quality index - as well as providing an AR experience to ensure the right breathing technique.</p>
              <p>We wanted to create an app which would have benefit for the user everyday. A weather and air quality report provides a daily indication of how these factors may influence their Asthma.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="Mundipharma product" src={Mundi3} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="Mundipharma product" src={Mundi4} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>My role on this project was to lead design which started with discovery sessions with key stakeholders where we applied a design thinking process to identify customer needs. What was found in our research is that asthama patients were using the asthma inhaler incorrectly.</p>

            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Mundipharma product" src={Mundi2} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.content}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <p>Ideation followed where we refined our concepts over several rounds and moved into prototyping. The prototype we created demonstrates that technologies such as augmented reality can ensure that the user has the correct inhalation technique.</p>
            <p>Augmented reality was able to detect the position of where the inhaler, the angle and post-inhaltion rituals.</p>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Mundipharma product" src={Mundi5} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="Mundipharma product" src={Mundi1} />
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default MundipharmaProject;
