import styles from "./Contact.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";

const Contact = (props) => {
  DocumentTitle("Jeff Lau - Contact");

  return (
    <>
      <section className={styles.contact}>
        <MainWrapper size="10">
          <h3>
            I’m keen to hear about new opportunities and discuss how we might be able to work together. The best way to get in touch is to send me a email or give me a call.
          </h3>
          <h1>
            <a href="mailto:jeff@owlry.com.au">jeff@owlry.com.au</a>
          </h1>
          <h1>
            <a href="tel:+61 488 332 122">+61 488 332 122</a>
          </h1>
        </MainWrapper>
      </section>
    </>
  );
};

export default Contact;
