import './App.scss';
import {
  Routes,
  Route
} from "react-router-dom";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import MacquarieProject from './pages/Project/Single/MacquarieProject';
import Project from './pages/Project/Project';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoanBaseProject from './pages/Project/Single/LoanBaseProject';
import ADHAProject from './pages/Project/Single/ADHAProject';
import NewsCorpProject from './pages/Project/Single/NewsCorpProject';
import ArthritisAustraliaProject from './pages/Project/Single/ArthritisAustraliaProject';
import MundipharmaProject from './pages/Project/Single/MundipharmaProject';
import TheLimbicProject from './pages/Project/Single/TheLimbicProject';
import TheCWKProject from './pages/Project/Single/TheCWKProject';
import FairfieldProject from './pages/Project/Single/FairfieldProject';
import CampbelltownProject from './pages/Project/Single/CampbelltownProject';
import ABCNProject from './pages/Project/Single/ABCNProject';
import Clients from './pages/Clients/Clients';
import Contact from './pages/Contact/Contact';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="projects" element={<Project />} />
        <Route path="projects/macquarie" element={<MacquarieProject />} />
        <Route path="projects/loan-base" element={<LoanBaseProject />} />
        <Route path="projects/adha" element={<ADHAProject />} />
        <Route path="projects/news-corp" element={<NewsCorpProject />} />
        <Route path="projects/arthritis" element={<ArthritisAustraliaProject />} />
        <Route path="projects/mundipharma" element={<MundipharmaProject />} />
        <Route path="projects/the-limbic" element={<TheLimbicProject />} />
        <Route path="projects/the-cwk" element={<TheCWKProject />} />
        <Route path="projects/fairfied" element={<FairfieldProject />} />
        <Route path="projects/campbelltown" element={<CampbelltownProject />} />
        <Route path="projects/abcn" element={<ABCNProject />} />
        <Route path="clients" element={<Clients />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
