import { Link } from "react-router-dom";

import styles from "./Home.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import mgl from "../../assets/mgl_design.jpg"
import CompetenciesAccordion from "../../components/CompetenciesAccordion/CompetenciesAccordion";
import Macquarie from "../../assets/clients/Macquarie";
import Adha from "../../assets/clients/Adha";
import NewsCorp from "../../assets/clients/NewsCorp";
import Pfizer from "../../assets/clients/Pfizer";
import Astrazeneca from "../../assets/clients/Astrazeneca";
import Mundipharma from "../../assets/clients/Mundipharma";
import SydneyUni from "../../assets/clients/SydneyUni";
import Unsw from "../../assets/clients/Unsw";
import NswGov from "../../assets/clients/NswGov";
import Arthritis from "../../assets/clients/Arthritis";
import Macquarie1 from "../../assets/projects/macquarie_1.jpg";
import LoanBase1 from "../../assets/projects/loanbase_1.jpg";
import Arthritis1 from "../../assets/projects/arthritis_1.jpg";
import NewsCorp1 from "../../assets/projects/newscorp_1.jpg";
import Adha1 from "../../assets/projects/adha_1.jpg";
import Mundi1 from "../../assets/projects/mundi_1.jpg";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";

const Home = (props) => {
  DocumentTitle("Jeff Lau");

  return (
    <>
      <section className={styles.mainTitle}>
        <MainWrapper size="10">
          <h1>Jeff Lau is a design leader, coach and ex-engineer working in Sydney.</h1>
        </MainWrapper>
      </section>
      <section className={styles.mainImage}>
        <MainWrapper>
          <div className={styles.imageRatio} >
            <img alt="mg" src={mgl} />
          </div>
        </MainWrapper>
      </section>
      <section className={styles.competencies}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <p><strong>I bridge the gap between design & development within organisations, I am both the technical specialist of the design team and the design specialist of the engineering teams.</strong></p>
            </div>
            <div className={styles.rightColumn}>
              <CompetenciesAccordion />
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.workedWith}>
        <MainWrapper>
          <div className={styles.logos}>
            <div className={styles.logo}><Macquarie /></div>
            <div className={styles.logo}><Adha /></div>
            <div className={styles.logo}><NewsCorp /></div>
            <div className={styles.logo}><Pfizer /></div>
            <div className={styles.logo}><Astrazeneca /></div>
            <div className={styles.logo}><Mundipharma /></div>
            <div className={styles.logo}><SydneyUni /></div>
            <div className={styles.logo}><Arthritis /></div>
            <div className={styles.logo}><NswGov /></div>
            <div className={styles.logo}><Unsw /></div>
          </div>
          <div className={styles.linkTo}>
            <Link to="/clients">View all 40+ clients</Link>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.projects}>
        <MainWrapper size="10">
          <div className={styles.projectsContainer}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <Link to="/projects/macquarie" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="Macquarie" src={Macquarie1} />
                  </div>
                  <h3>Macquarie Group</h3>
                  <p>Product Design, Accessibility & Design System</p>
                </Link>
                <Link to="/projects/loan-base" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="LoanBase" src={LoanBase1} />
                  </div>
                  <h3>Loan Base</h3>
                  <p>Product Design, Design System & Accessibility</p>
                </Link>
                <Link to="/projects/adha" className={`${styles.project} ${styles.tall}`}>
                  <div className={styles.projectImage}>
                    <img alt="Adha" src={Adha1} />
                  </div>
                  <h3>Australian Digital Health Agency</h3>
                  <p>Product Design & Accessibility</p>
                </Link>
              </div>
              <div className={styles.rightColumn}>
                <Link to="/projects/news-corp" className={`${styles.project} ${styles.tall}`}>
                  <div className={styles.projectImage}>
                    <img alt="NewsCorp" src={NewsCorp1} />
                  </div>
                  <h3>News Corp</h3>
                  <p>Product Design</p>
                </Link>
                <Link to="/projects/arthritis" className={`${styles.project}`}>
                  <div className={styles.projectImage}>
                    <img alt="Arthritis" src={Arthritis1} />
                  </div>
                  <h3>Arthritis Australia</h3>
                  <p>Product Design, Design System & Accessibility</p>
                </Link>
                <Link to="/projects/mundipharma" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="Mundi" src={Mundi1} />
                  </div>
                  <h3>Mundipharma</h3>
                  <p>Product Design</p>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.linkTo}>
            <Link to="/projects">View all work</Link>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.workingWithMe}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>
                Working with me your organisation will gain quality in delivery, the products we deliver together will be highly accessible, visually correct, consistent and sustainable for future initiatives.
                <br /><br />
                Crafting experiences that meet your customers needs and then guiding development teams to bring the experience to life, meeting accessibility requirements and utilising a design system methodology are foundational elements through the process.
              </h3>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.stats}>
                <div className={styles.largeText}>6+</div>
                <p>years design experience</p>
              </div>
              <div className={styles.stats}>
                <div className={styles.largeText}>4+</div>
                <p>years development experience</p>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.imageSlide}>
        <ImageCarousel />
      </section>
      <section className={styles.experiences}>
        <MainWrapper>
          <div className={styles.experience}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <p>2022 - Present</p>
              </div>
              <div className={styles.rightColumn}>
                <h3>Canva, Product Designer</h3>
              </div>
            </div>
          </div>
          <div className={styles.experience}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <p>2021 - 2022</p>
              </div>
              <div className={styles.rightColumn}>
                <h3>Macquarie Group, Product Designer</h3>
              </div>
            </div>
          </div>
          <div className={styles.experience}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <p>2016 - 2021</p>
              </div>
              <div className={styles.rightColumn}>
                <h3>Wax Agency, Senior UI/UX Designer</h3>
                <p>Pfizer, News Corp, Mundipharma, Australian Digital Health Agency, and <Link to="/clients"> more</Link>.</p>
              </div>
            </div>
          </div>
          <div className={styles.experience}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <p>2018 - 2021</p>
              </div>
              <div className={styles.rightColumn}>
                <h3>Owlry, Lead Product Designer</h3>
                <p>UNSW, ABCN, University of Sydney, NSW Health, and <Link to="/clients"> more</Link>.</p>
              </div>
            </div>
          </div>
          <div className={styles.experience}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <p>2019</p>
              </div>
              <div className={styles.rightColumn}>
                <h3>Sahya Blockchain, Lead Designer</h3>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default Home;
