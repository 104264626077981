import styles from "../Project.module.scss";
import MainWrapper from "../../../components/MainWrapper/MainWrapper"
import NewsCorp1 from "../../../assets/projects/newscorp_1.jpg"
import NewsCorp2 from "../../../assets/projects/newscorp_2.jpg"
import NewsCorp3 from "../../../assets/projects/newscorp_3.jpg"
import NewsCorp4 from "../../../assets/projects/newscorp_4.jpg"
import NewsCorp5 from "../../../assets/projects/newscorp_5.jpg"
import NewsCorp6 from "../../../assets/projects/newscorp_6.jpg"
import NewsCorp7 from "../../../assets/projects/newscorp_7.jpg"
import NewsCorp8 from "../../../assets/projects/newscorp_8.jpg"
import NewsCorp9 from "../../../assets/projects/newscorp_9.jpg"
import DocumentTitle from "../../../components/DocumentTitle/DocumentTitle";

const NewsCorpProject = () => {
  DocumentTitle("Jeff Lau - News Corp");

  return (
    <>
      <section className={styles.projectTitle}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3><strong>News Corp</strong></h3>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>News Corp with the the vast amount of documents they circulate on a daily basis, providing feedback like markups and commenting was key for collaboration. This functionality was limited to basic Adobe Acrobat that required lots of back and forth.</p>
              <p>In this project, I led the design of a feedback system that would be integrated with the News Corp internal file system. The prototype demonstrates a seamless experience where users can remain on the portal to create location-specific comments, create threaded conversations, ability to preview old versions of comments, and recieve time-relevant notifications.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="News Corp product"  src={NewsCorp1} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.titleContent}>
        <MainWrapper size="10">
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3>Product Design</h3>
            </div>
            <div className={styles.rightColumn}>
              <p>The lifecycle of a document begins with creation, then sharing with others to get feedback — iterating until approval. Users could not complete this feedback loop within News Corp and thus left the system to complete their workflows. By interviewing employees we found they were having difficulties receiving feedback, editing, iterating and tracking content.</p>
              <p>Because they were unable to do this, they were forced to use other software to annotate and markup their content. Their ultimate goal was to have everything within their internal system without the annoyance of going out and back in.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="News Corp product"  src={NewsCorp2} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>I explored all the different ways the user could create feedback: from the sidebar, toolbar, file-preview and even the header. I also explored different ways the user can formulate feedback from; annotations, point annotations, drawing and text highlighting. As part of my methodology, I detailed out every feedback flow, tested it with users and weighed up the pros of cons.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="News Corp product"  src={NewsCorp4} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>In the design phase, I created low-fidelity, high-fidelity wireframes to map out the shell of the interface. The design phase was heavily influenced by the results of the usability testing.</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="News Corp product"  src={NewsCorp5} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="News Corp product"  src={NewsCorp6} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="News Corp product"  src={NewsCorp3} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>The sidebar went through a series of adjustments and changes, where each iteration improved on the previous version. With every version, the design was critiqued, assessed and underwent usability testing before we arrived to the MVP.</p>
              <p>Each iteration was tested with at least 20 participants - with the goal of identifying any usability problems, collect qualitative and quantitative data and determine the participant's satisfaction with using the commenting system. Because I was able to run these tests in the background, I was able to iterate very quickly and get useful findings within just half-a-day.</p>
              <p>We were able to increase the ‘ease of use’ of the sidebar from an initial 3.5/5 (Version 1), to 4.6/5 (MVP) across all ages, a 31.42% increase [n=44].</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageFull}>
        <MainWrapper size="10 ">
          <div className={styles.imageRatio} >
            <img alt="News Corp product"  src={NewsCorp7} />
          </div>
        </MainWrapper>
      </section>

      <section className={styles.contentHalf}>
        <MainWrapper size="10">
          <div className={styles.rightColumn}>
            <div className={styles.content}>
              <p>With the unmoderated user testing, we were able to validate concepts and designs quickly with a large, diverse group of participants. With the large sample size, we were able to get feedback quickly in their natural environment.</p>
              <p>For example, we asked questions such as:
                “On a scale from 1 to 5, how clear was it to you that you needed to click on the draw button to annotate the file?”</p>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.imageHalf}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <div className={styles.imageRatio} >
                <img alt="News Corp product"  src={NewsCorp8} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.imageRatio} >
                <img alt="News Corp product"  src={NewsCorp9} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section className={styles.content}>
        <MainWrapper size="10">
          <h3>A post-feedback survey gave the feature a 9.6/10 - with one special stakeholder describing it as the “best f---ing thing to ever happen.”</h3>
        </MainWrapper>
      </section>
    </>
  );
};

export default NewsCorpProject;
